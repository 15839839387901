import React, {useState, useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { RichText } from 'prismic-reactjs'
import { useForm } from "react-hook-form";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { SliceZones } from '../components/slices/slice-zones'
import Layout from '../components/global/layout'
import BlogRelatedPostsSidebar from '../components/blog/related-posts-sidebar'
import CustomLink from '../utils/prismic-content-link'
import BlogHeader from '../components/blog/header'
import {pages, siteMetadata} from '../site-config'


const OurInsightArticle = ({ data, pageContext }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [state, setState] = useState({
        isSubmitted: false,
        isError: false
      });

    useEffect(() => {
        ReactRecaptcha3.init('6Lf8Y-ogAAAAAHK0AtJ6H4DN-KvX3gbgewzgF8bW').then(status => {
            // status: success/error
            // success - script is loaded and greaptcha is ready
            // error - script is not loaded
            // console.log(status)
        });
    }, []);

    const onSubmit = (data) => {
        ReactRecaptcha3.getToken().then(token => {
            setState({
                isSubmitted: true,
            });
            // console.log(token)
        }, error => {
            setState({
                isError: true,
            });
            // console.log(error)
        })
    }

    if (!data)
        return null

    const document = data.prismicInsightArticle
    const documentsByTag = data.allPrismicInsightArticle.nodes
    const author = document.data.author.document !== null && document.data.author.document !== undefined ? document.data.author.document.data : null

    const pageUrl = `${siteMetadata.siteUrl}${document.url}`

    return (
    <Layout pageTitle={document.data.title.text}
            metaTitle={document.data.meta_title.text}
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <div className="c-article">
        <div className="container">
            <div className="row">
                <div className="c-article__main">
                    <div className="c-article__header">
                        <h1 className="c-article__header-title">{document.data.title.text}</h1>
                        <BlogHeader author={author} postDate={document.data.date} />
                    </div>
                    <div className="c-article__body cms-content">
                      <RichText render={document.data.content.raw} serializeHyperlink={CustomLink} />
                    </div>
                </div>
                <div className="c-article__sidebar">
                    <div className="c-article__share">
                        <div className="c-article__share-title" id="share-label">Share this story</div>
                        <ul className="c-article__share-items" aria-labelledby="share-label">
                            <li className="c-article__share-item">
                                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`} target="_blank" className="c-article__share-link" rel="noreferrer">
                                    <span className="u-visually-hide">Share on LinkedIn</span>
                                    <svg className="c-article__share-link-icon" viewBox="0 0 32 32"><path d="M27.263 27.269h-4.739v-7.425c0-1.771-0.036-4.049-2.469-4.049-2.471 0-2.848 1.927-2.848 3.919v7.556h-4.739v-15.269h4.552v2.081h0.061c0.636-1.2 2.183-2.467 4.493-2.467 4.801 0 5.689 3.16 5.689 7.273zM7.116 9.911c-1.525 0-2.751-1.235-2.751-2.753 0-1.517 1.227-2.751 2.751-2.751 1.52 0 2.752 1.233 2.752 2.751 0 1.519-1.233 2.753-2.752 2.753zM9.492 27.269h-4.752v-15.269h4.752zM29.633 0h-27.272c-1.305 0-2.361 1.032-2.361 2.305v27.389c0 1.275 1.056 2.305 2.361 2.305h27.268c1.304 0 2.371-1.031 2.371-2.305v-27.389c0-1.273-1.067-2.305-2.371-2.305z"></path></svg>
                                </a>
                            </li>
                            <li className="c-article__share-item">
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`} target="_blank" className="c-article__share-link" rel="noreferrer">
                                    <span className="u-visually-hide">Share on Facebook</span>
                                    <svg className="c-article__share-link-icon" viewBox="0 0 32 32"><path d="M31.997 15.999c0-8.836-7.163-15.999-15.999-15.999s-15.999 7.163-15.999 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18h-4.062v-4.625h4.062v-3.525c0-4.010 2.389-6.225 6.043-6.225 1.75 0 3.581 0.313 3.581 0.313v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-0.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z"></path></svg>
                                </a>
                            </li>
                            <li className="c-article__share-item">
                                <a href={`https://twitter.com/intent/tweet?url=${pageUrl}`} target="_blank" className="c-article__share-link" rel="noreferrer">
                                    <span className="u-visually-hide">Share on Twitter</span>
                                    <svg className="c-article__share-link-icon" viewBox="0 0 32 28"><path fillRule="evenodd" clipRule="evenodd" d="M32 3.7971a12.7016 12.7016 0 01-3.7697 1.0726c1.3561-.8424 2.3967-2.1783 2.8869-3.7696-1.2697.78-2.6728 1.3475-4.1716 1.6518-1.1946-1.3242-2.9019-2.151-4.7915-2.151-3.6231 0-6.5626 3.052-6.5626 6.8158 0 .5343.0563 1.053.169 1.5523-5.4563-.2847-10.2929-2.9955-13.5329-7.124-.5653 1.0102-.8884 2.1823-.8884 3.4304 0 2.3636 1.1589 4.4502 2.9207 5.673-1.0744-.0332-2.0886-.3432-2.9752-.8503v.0839c0 3.3036 2.2633 6.0591 5.2686 6.6832-.5504.1599-1.1307.2399-1.73.2399-.4225 0-.8357-.041-1.2358-.121.8358 2.7069 3.2588 4.6785 6.1325 4.7311-2.2464 1.8293-5.0788 2.9194-8.1535 2.9194-.5297 0-1.0537-.0312-1.5665-.0936 2.9057 1.9307 6.358 3.0598 10.0637 3.0598 12.0773 0 18.6794-10.3846 18.6794-19.3924a22.81 22.81 0 00-.0169-.8834C30.009 6.3636 31.1229 5.1642 32 3.797z" fill="#000000"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="c-article__subscribe">
                        <div className="c-article__subscribe-title" id="share-label">Subscribe</div>
                        {!state.isSubmitted ?
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="c-contact__form-fields">
                                    <div className="c-contact__form-field">
                                        <div className="c-form__textbox-field">
                                            <label htmlFor="email" className="c-form__label">Email</label>
                                            <input id="email" name="email" placeholder="Email" className={errors.contactemail ? "c-form__textbox error" : "c-form__textbox"} {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                            {errors.email && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                        </div>
                                        <br/>
                                        <div className="c-contact__form-field">
                                            <p className="c-contact__form-consent">By clicking the 'Subscribe' button you're confirming that you agree with our following <Link to={pages.privacyPolicy}>Privacy Policy</Link>.</p>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <input type="submit" value="Subscribe" className="c-contact__submit c-btn c-btn--large c-btn--primary" />
                                        </div>
                                    </div>
                                </div>
                                {state.isError ? <p className="c-contact__summary">Unfortunately, your submission could not be sent. Please try again later.</p> : null}
                            </form>
                            : <p className="c-contact__summary">Thank you for subscribing to our insights.</p>}
                    </div>
                    <BlogRelatedPostsSidebar posts={documentsByTag}  />
                </div>
            </div>
        </div>
      </div>
      <SliceZones slices={document.data.body} />
    </Layout>
    )
}

export const query = graphql`
  query OurInsightArticleQuery($id: String, $tags: [String]) {
    prismicInsightArticle(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        title {
          text
        }
        summary {
          text
        }
        date
        content {
          raw
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                linkedin_url {
                  url
                }
                email_address {
                  text
                }
                image {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicInsightArticleDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
              display_tag
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                      industry
                      service
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
    allPrismicInsightArticle (
      sort: {fields: data___date, order: DESC}
      limit: 10
      filter: {tags: {in: $tags}, id: {ne: $id}}
    ) {
      nodes {
        data {
          summary {
            text
          }
          title {
            text
          }
          date
          listing_image {
            alt
            url
          }
        }
        url
        tags
      }
    }
  }
`

export default withPrismicPreview(OurInsightArticle, repositoryConfigs)